"use strict";

window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Faq = function Faq() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $accordionsItems = document.querySelectorAll('.block-faq .accordion-item');
    var $allFilters = document.querySelectorAll('.block-faq .filter-item');
    if ($accordionsItems.length) {
      $accordionsItems.forEach(function ($accordionItem) {
        var $accordionTrigger = $accordionItem.querySelector('.accordion-heading');
        var $accordionContent = $accordionItem.querySelector('.accordion-content');
        $accordionTrigger.addEventListener('click', function () {
          if ($accordionItem.classList.contains('is-opened')) {
            $accordionItem.classList.remove('is-opened');
            $accordionContent.style.maxHeight = null;
          } else {
            $accordionItem.classList.add('is-opened');
            $accordionContent.style.maxHeight = $accordionContent.scrollHeight + 40 + "px";
          }
        });
      });
      $accordionsItems.forEach(function ($accordionItem) {
        $accordionItem.classList.remove("is-visible");
        $accordionItem.classList.add("is-hidden");
      });
      $accordionsItems.forEach(function ($accordionItem, index) {
        $accordionItem.classList.remove("is-hidden");
        $accordionItem.classList.add("is-visible");
        $accordionItem.style.animationDelay = index * 0.3 + "s";
      });
    }
    if ($allFilters.length) {
      $allFilters.forEach(function ($filter) {
        $filter.addEventListener('click', function (e) {
          e.preventDefault();
          var filterCategory = $filter.dataset.category;
          var index = 0;
          $allFilters.forEach(function ($element) {
            $element.classList.remove('is-active');
          });
          $filter.classList.add('is-active');
          if (filterCategory == 'all') {
            $accordionsItems.forEach(function ($accordionItem) {
              var $accordionContent = $accordionItem.querySelector('.accordion-content');
              $accordionContent.style.maxHeight = null;
              $accordionItem.classList.remove("is-visible", "is-opened");
              setTimeout(function () {
                var index = 0;
                $accordionsItems.forEach(function ($accordionItem) {
                  console.log(index);
                  $accordionItem.classList.add("is-visible");
                  $accordionItem.style.animationDelay = index * 0.2 + "s";
                  index++;
                });
              });
            });
          } else {
            $accordionsItems.forEach(function ($accordionItem) {
              var itemCategory = $accordionItem.dataset.category;
              var $accordionContent = $accordionItem.querySelector('.accordion-content');
              $accordionContent.style.maxHeight = null;
              $accordionItem.classList.remove("is-visible", "is-opened");
              setTimeout(function () {
                if (itemCategory == filterCategory) {
                  $accordionItem.classList.add("is-visible");
                  $accordionItem.style.animationDelay = index * 0.2 + "s";
                  index++;
                }
              });
            });
          }
        });
      });
    }
    return true;
  };
  // Expose public methods & properties
  return {
    init: init
  };
}();