"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
window.site = window.site || {};
/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.FilesUploads = function FilesUploads() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var fileUploadFields = document.querySelectorAll('.custom-file-upload');
    fileUploadFields.forEach(function (field) {
      var fieldId = field.dataset.fieldId;
      var dropZone = field.querySelector("#dropZone_".concat(fieldId));
      var fileInput = field.querySelector("#fileInput_".concat(fieldId));
      var fileList = field.querySelector("#fileList_".concat(fieldId));
      var files = new Map(); // Use a Map to store files

      dropZone.addEventListener('click', function () {
        return fileInput.click();
      });
      dropZone.addEventListener('dragover', function (e) {
        e.preventDefault();
        dropZone.classList.add('dragover');
      });
      dropZone.addEventListener('dragleave', function () {
        dropZone.classList.remove('dragover');
      });
      dropZone.addEventListener('drop', function (e) {
        e.preventDefault();
        dropZone.classList.remove('dragover');
        handleFiles(e.dataTransfer.files);
      });
      fileInput.addEventListener('change', function (e) {
        handleFiles(e.target.files);
      });
      function handleFiles(newFiles) {
        var _iterator = _createForOfIteratorHelper(newFiles),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var file = _step.value;
            if (!files.has(file.name)) {
              files.set(file.name, file);
              addFileToList(file);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        updateFileInput();
      }
      function addFileToList(file) {
        var fileItem = document.createElement('div');
        fileItem.className = 'file-item';
        fileItem.innerHTML = "\n                  <span>".concat(file.name, "</span>\n                  <button type=\"button\" class=\"remove-file\">&times;</button>\n              ");
        fileList.appendChild(fileItem);
        fileItem.querySelector('.remove-file').addEventListener('click', function () {
          fileList.removeChild(fileItem);
          files.delete(file.name);
          updateFileInput();
        });
      }
      function updateFileInput() {
        var dataTransfer = new DataTransfer();
        files.forEach(function (file) {
          dataTransfer.items.add(file);
        });
        fileInput.files = dataTransfer.files;
        //   console.log('Updated files:', fileInput.files);
      }
    });

    return true;
  };
  // Expose public methods & properties
  return {
    init: init
  };
}();