"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Header = function Header() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $trigger = document.querySelector('.menu-icon-container');
    var $menu = document.querySelector('.mobile-menu');
    if ($trigger && $menu) {
      // Responsive Menu
      var $body = document.querySelector('body');
      var focusableElements = $menu.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
      focusableElements.forEach(function (el) {
        return el.setAttribute('tabindex', '-1');
      });
      var openMenu = function openMenu() {
        $trigger.classList.add('is-opened');
        $menu.classList.add('is-active');
        $body.style.overflow = 'hidden';
        focusableElements.forEach(function (el) {
          return el.removeAttribute('tabindex');
        });
      };
      var closeMenu = function closeMenu() {
        $trigger.classList.remove('is-opened');
        $menu.classList.remove('is-active');
        $body.style.overflow = 'auto';
        focusableElements.forEach(function (el) {
          return el.setAttribute('tabindex', '-1');
        });
      };
      $trigger.addEventListener('click', function () {
        if ($trigger.classList.contains('is-opened')) {
          closeMenu();
        } else {
          openMenu();
        }
      });

      // Sub-Menu
      var $subMenuTriggers = document.querySelectorAll('.header .header-container .bottom-line .nav-link-container.has-sub-menu');
      $subMenuTriggers.forEach(function ($subMenuTrigger) {
        var $subMenu = $subMenuTrigger.querySelector('.nav-sub-links');
        var focusableElements = $subMenu.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
        setTimeout(function () {
          $subMenu.classList.add('is-animated');
        }, 2000);
        var checkPosition = function checkPosition() {
          $subMenu.style.marginLeft = '0';
          var positionRight = $subMenu.getBoundingClientRect().right;
          var screenWidth = window.outerWidth - 20;
          if (positionRight >= screenWidth) {
            var delta = positionRight - screenWidth;
            $subMenu.style.marginLeft = "-".concat(delta, "px");
          }
        };
        $subMenuTrigger.addEventListener('mouseover', function (e) {
          e.preventDefault();
          checkPosition();
          $subMenuTrigger.classList.add('sub-menu-shown');
          focusableElements.forEach(function (el) {
            return el.removeAttribute('tabindex');
          });
        });
        $subMenuTrigger.addEventListener('mouseout', function (e) {
          e.preventDefault();
          $subMenuTrigger.classList.remove('sub-menu-shown');
          focusableElements.forEach(function (el) {
            return el.setAttribute('tabindex', '-1');
          });
        });
      });

      // Search
      var $searchTrigger = document.querySelector('.nav-link-container.search');
      if ($searchTrigger) {
        document.addEventListener('click', function (e) {
          if (!e.target.closest('.nav-link-container.search') && $searchTrigger.classList.contains('active')) {
            $searchTrigger.classList.remove('active');
          }
          if (e.target.closest('.nav-link-container.search') && !$searchTrigger.classList.contains('active')) {
            $searchTrigger.classList.add('active');
          }
        });
      }
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();