"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.DataLayers = function DataLayers() {
  /**
   * Has the class been initialized?
   * @private
   */
  var $dataLayersElements = document.querySelectorAll('[data-layers]');
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited || !window.dataLayer) {
      return false;
    }
    inited = true;
    if ($dataLayersElements.length) {
      $dataLayersElements.forEach(function ($dataLayersElement) {
        var event = $dataLayersElement.getAttribute('data-layers');
        var pageLanguage = $dataLayersElement.getAttribute('data-layers-page-lang');
        var currentSite = $dataLayersElement.getAttribute('data-layers-site');
        switch (event) {
          case 'pageview':
            var pageCaterogy = $dataLayersElement.getAttribute('data-layers-page-category');
            var pageCaterogyAlt = $dataLayersElement.getAttribute('data-layers-page-category-alt');
            var itemId = $dataLayersElement.getAttribute('data-layers-item-id');
            var itemName = $dataLayersElement.getAttribute('data-layers-item-name');
            var itemCategory = $dataLayersElement.getAttribute('data-layers-item-category');
            var itemCategoryAlt = $dataLayersElement.getAttribute('data-layers-item-category-alt');
            var itemVariant = $dataLayersElement.getAttribute('data-layers-item-variant');
            if (currentSite === 'blog') {
              window.dataLayer.push({
                "event": event,
                "event_name": 'page_view',
                "page": {
                  "page_language": pageLanguage,
                  "item_id": itemId === 'null' ? null : itemId,
                  "item_name": itemName === 'null' ? null : itemName,
                  "item_category": itemCategory === 'null' ? null : itemCategory,
                  "item_category2": itemCategoryAlt === 'null' ? null : itemCategoryAlt,
                  "item_variant": itemVariant === 'null' ? null : itemVariant
                }
              });
            } else {
              window.dataLayer.push({
                "event": event,
                "event_name": 'page_view',
                "page": {
                  "page_language": pageLanguage,
                  "page_category": pageCaterogy === 'null' ? null : pageCaterogy,
                  "page_category2": pageCaterogyAlt === 'null' ? null : pageCaterogyAlt
                }
              });
            }
            webVitals.onCLS(sendPerformanceToGTM);
            webVitals.onFID(sendPerformanceToGTM);
            webVitals.onLCP(sendPerformanceToGTM);
            var searchTerm = $dataLayersElement.getAttribute('data-layers-search');
            var searchCount = $dataLayersElement.getAttribute('data-layers-search-count');
            if (searchTerm && searchCount) {
              dataLayer.push({
                "event": event,
                "event_name": "view_search_results",
                "interaction": {
                  "search_term": searchTerm,
                  "search_results_count": parseInt(searchCount)
                }
              });
            }
            break;
          case 'ecom_interaction':
            dataLayer.push({
              ecommerce: null
            });
            var listName = $dataLayersElement.getAttribute('data-layers-list-name');
            var itemsElements = $dataLayersElement.querySelectorAll('[data-layers-item]');
            if (listName !== 'single' && itemsElements.length) {
              var items = [];
              itemsElements.forEach(function (itemElement) {
                var itemId = itemElement.getAttribute('data-layers-item-id');
                var itemName = itemElement.getAttribute('data-layers-item-name');
                var itemCategory = itemElement.getAttribute('data-layers-item-category');
                var itemCategoryAlt = itemElement.getAttribute('data-layers-item-category-alt');
                var itemIndex = itemElement.getAttribute('data-layers-item-index');
                items.push({
                  "item_id": itemId,
                  "item_name": itemName,
                  "item_category": itemCategory === 'null' ? null : itemCategory,
                  "item_category2": itemCategoryAlt === 'null' ? null : itemCategoryAlt,
                  "item_list_name": listName,
                  "item_variant": 'article',
                  "index": parseInt(itemIndex)
                });
                itemElement.addEventListener('click', function () {
                  dataLayer.push({
                    "event": event,
                    "event_name": "select_item",
                    "ecommerce": {
                      "items": [{
                        "item_id": itemId,
                        "item_name": itemName,
                        "item_category": itemCategory === 'null' ? null : itemCategory,
                        "item_category2": itemCategoryAlt === 'null' ? null : itemCategoryAlt,
                        "item_list_name": listName,
                        "item_variant": "article",
                        "index": parseInt(itemIndex)
                      }]
                    }
                  });
                });
              });
              dataLayer.push({
                "event": event,
                "event_name": 'view_item_list',
                "ecommerce": {
                  "items": items
                }
              });
            } else {
              var _itemId = $dataLayersElement.getAttribute('data-layers-item-id');
              var _itemName = $dataLayersElement.getAttribute('data-layers-item-name');
              var _itemCategory = $dataLayersElement.getAttribute('data-layers-item-category');
              var _itemCategoryAlt = $dataLayersElement.getAttribute('data-layers-item-category-alt');
              dataLayer.push({
                "event": event,
                "event_name": "view_item",
                "ecommerce": {
                  "items": [{
                    "item_id": _itemId,
                    "item_name": _itemName,
                    "item_category": _itemCategory === 'null' ? null : _itemCategory,
                    "item_category2": _itemCategoryAlt === 'null' ? null : _itemCategoryAlt,
                    "item_variant": "article"
                  }]
                }
              });
            }
            break;
          case 'user_interaction':
            setTimeout(function () {
              var linksElements = $dataLayersElement.querySelectorAll('a');
              if (linksElements.length) {
                linksElements.forEach(function (linkElement) {
                  var linkName = linkElement.getAttribute('data-layers-link');
                  var linkType = linkElement.getAttribute('data-layers-link-type');
                  var interactionLocation = linkElement.getAttribute('data-layers-interaction-location');
                  var url = linkElement.getAttribute('href');
                  linkElement.addEventListener('click', function (e) {
                    if (linkType && linkType === 'réseau social') {
                      dataLayer.push({
                        "event": event,
                        "event_name": "share",
                        "interaction": {
                          "link_url": url,
                          "link_text": linkName
                        }
                      });
                    } else {
                      if (!linkType) {
                        if (url.indexOf('http') === 0) {
                          linkType = 'lien sortant';
                        } else {
                          linkType = 'lien interne';
                        }
                      }
                      if (!linkName) {
                        linkName = linkElement.textContent;
                      }
                      var interaction = {
                        "click_type": linkType,
                        "link_url": url,
                        "link_text": linkName
                      };
                      if (interactionLocation && currentSite === 'energir') {
                        interaction = _objectSpread(_objectSpread({}, interaction), {}, {
                          "interaction_location": interactionLocation
                        });
                      }
                      if ($dataLayersElement.classList.contains('navigation-submenu') && currentSite === 'energir') {
                        interaction = {
                          "click_type": linkElement.classList.contains('button') ? 'bouton' : 'sous-menu',
                          "link_url": url,
                          "link_text": linkName,
                          "interaction_location": 'menu'
                        };
                      }
                      if ($dataLayersElement.classList.contains('content-quintuple') && currentSite === 'energir') {
                        interaction = {
                          "click_type": 'lien rapide',
                          "link_url": url,
                          "link_text": linkElement.nextElementSibling.textContent
                        };
                      }
                      dataLayer.push({
                        "event": event,
                        "event_name": "click",
                        "interaction": interaction
                      });
                      if (linkType !== 'lien sortant' && isExternalURL(url)) {
                        linkType = 'lien externe';
                        dataLayer.push({
                          "event": event,
                          "event_name": "click",
                          "interaction": {
                            "click_type": linkType,
                            "link_url": url,
                            "link_text": linkName
                          }
                        });
                      }
                    }
                  });
                });
              }
            }, 1000);
          default:
            break;
        }
      });
    }

    // Check for all links which does not contain data-layers-link attribute
    var $links = document.querySelectorAll('section.content-blocks a:not([data-layers-link])');

    // Check if the current site is energir by checking hostname (energir.test, energir.dev.hff.io, staging.energir.com, energir.com)
    var currentSite = window.location.hostname.split('.')[0];
    if (currentSite !== 'energir') {
      currentSite = window.location.hostname.split('.')[1];
    }
    if ($links.length && currentSite === 'energir') {
      $links.forEach(function ($link) {
        var url = $link.getAttribute('href');
        var interactionLocation = $link.closest('.flickity-enabled') ? 'carrousel' : 'page';
        if (url.indexOf('tel:') === 0) {
          // If the link is phone
          $link.addEventListener('click', function (e) {
            var linkName = $link.textContent;
            var linkType = 'numéro de téléphone';
            dataLayer.push({
              "event": 'user_interaction',
              "event_name": "click",
              "interaction": {
                "click_type": linkType,
                "link_url": url,
                "link_text": linkName,
                "interaction_location": interactionLocation
              }
            });
          });
        } else if (url.indexOf('mailto:') === 0) {
          // If the link is email
          $link.addEventListener('click', function (e) {
            var linkName = $link.textContent;
            var linkType = 'adresse courriel';
            dataLayer.push({
              "event": 'user_interaction',
              "event_name": "click",
              "interaction": {
                "click_type": linkType,
                "link_url": url,
                "link_text": linkName,
                "interaction_location": interactionLocation
              }
            });
          });
        } else if (isExternalURL(url)) {
          $link.addEventListener('click', function (e) {
            var linkName = $link.textContent;
            var linkType = 'lien externe';
            dataLayer.push({
              "event": 'user_interaction',
              "event_name": "click",
              "interaction": {
                "click_type": linkType,
                "link_url": url,
                "link_text": linkName,
                "interaction_location": interactionLocation
              }
            });
            if ($link.classList.contains('button')) {
              dataLayer.push({
                "event": 'user_interaction',
                "event_name": "click",
                "interaction": {
                  "click_type": 'bouton',
                  "link_url": url,
                  "link_text": linkName,
                  "interaction_location": interactionLocation
                }
              });
            }
          });
        } else {
          $link.addEventListener('click', function (e) {
            var linkName = $link.textContent;
            var linkType = 'lien interne';
            dataLayer.push({
              "event": 'user_interaction',
              "event_name": "click",
              "interaction": {
                "click_type": linkType,
                "link_url": url,
                "link_text": linkName,
                "interaction_location": interactionLocation
              }
            });
            if ($link.classList.contains('button')) {
              dataLayer.push({
                "event": 'user_interaction',
                "event_name": "click",
                "interaction": {
                  "click_type": 'bouton',
                  "link_url": url,
                  "link_text": linkName,
                  "interaction_location": interactionLocation
                }
              });
            }
          });
        }
      });
    }
    return true;
  };
  var sendPerformanceToGTM = function sendPerformanceToGTM(_ref) {
    var name = _ref.name,
      delta = _ref.delta,
      value = _ref.value,
      id = _ref.id,
      rating = _ref.rating;
    window.dataLayer.push({
      "event": 'pageview',
      "event_name": 'page_load_performance',
      "page": {
        "page_vital_name": name,
        "page_vital_value": value,
        "page_vital_status": rating.replace('-', ' ')
      }
    });
  };
  var isExternalURL = function isExternalURL(url) {
    return new URL(url).origin !== location.origin;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();