"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.OverflightAreas = function OverflightAreas() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allBoxes = document.querySelectorAll(".boxContainer");
    var allCloseCross = document.querySelectorAll(".svg-plus");
    if (allBoxes) {
      allBoxes.forEach(function (box) {
        box.addEventListener('click', function () {
          removeAllClasses();
          box.classList.add("active");
        });
      });
    }
    if (allCloseCross) {
      allCloseCross.forEach(function (cross) {
        cross.addEventListener('click', function (e) {
          e.stopPropagation();
          removeAllClasses();
        });
      });
    }
    var removeAllClasses = function removeAllClasses() {
      allBoxes.forEach(function (box) {
        box.classList.remove("active");
      });
    };
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();