"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Navigation = function Navigation() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $navigationTriggers = document.querySelectorAll('.submenu-sublink');
    if ($navigationTriggers.length) {
      $navigationTriggers.forEach(function ($trigger) {
        var $submenuBlocks = $trigger.closest('.navigation-submenu').querySelectorAll('.submenu-blocks .navigation-block');
        $submenuBlocks.forEach(function ($submenuBlock) {
          var focusableElements = $submenuBlock.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
          focusableElements.forEach(function (el) {
            return el.setAttribute('tabindex', '-1');
          });
        });
        $trigger.addEventListener('mouseleave', function () {
          $submenuBlocks.forEach(function ($submenuBlock) {
            var focusableElements = $submenuBlock.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
            focusableElements.forEach(function (el) {
              return el.setAttribute('tabindex', '-1');
            });
          });
        });
        $trigger.addEventListener('mouseover', function (e) {
          e.preventDefault();
          var submenuId = $trigger.dataset.submenu;
          var $submenuLinks = $trigger.closest('.submenu-sublinks').querySelectorAll('.submenu-sublink');
          if ($submenuLinks.length) {
            $submenuLinks.forEach(function ($submenuLink) {
              if ($submenuLink.dataset.submenu === submenuId) {
                $submenuLink.classList.add('is-active');
              } else {
                $submenuLink.classList.remove('is-active');
              }
            });
          }
          if ($submenuBlocks.length) {
            $submenuBlocks.forEach(function ($submenuBlock) {
              var focusableElements = $submenuBlock.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
              if ($submenuBlock.dataset.submenu === submenuId) {
                $submenuBlock.classList.add('is-active');
                focusableElements.forEach(function (el) {
                  return el.removeAttribute('tabindex');
                });
              } else {
                $submenuBlock.classList.remove('is-active');
                focusableElements.forEach(function (el) {
                  return el.setAttribute('tabindex', '-1');
                });
              }
            });
          }
        });
      });
    }
    var $navigationItems = document.querySelectorAll('.navigation .navigation-item');
    $navigationItems.forEach(function ($navigationItem) {
      var focusableElements = $navigationItem.querySelectorAll('.submenu-sublinks a, .navigation-submenu:not(.is-visible) .navigation-block a, .navigation-submenu:not(.is-visible) .navigation-block button');
      $navigationItem.addEventListener('mouseenter', function () {
        focusableElements.forEach(function (el) {
          return el.removeAttribute('tabindex');
        });
        var $submenuBlocks = $navigationItem.querySelectorAll('.submenu-blocks .navigation-block');
        $submenuBlocks.forEach(function ($submenuBlock) {
          var focusableElements = $submenuBlock.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
          if ($submenuBlock.classList.contains('is-active')) {
            focusableElements.forEach(function (el) {
              return el.removeAttribute('tabindex');
            });
          } else {
            focusableElements.forEach(function (el) {
              return el.setAttribute('tabindex', '-1');
            });
          }
        });
      });
      $navigationItem.addEventListener('mouseleave', function () {
        focusableElements.forEach(function (el) {
          return el.setAttribute('tabindex', '-1');
        });
        var $submenuBlocks = $navigationItem.querySelectorAll('.submenu-blocks .navigation-block');
        $submenuBlocks.forEach(function ($submenuBlock) {
          var focusableElements = $submenuBlock.querySelectorAll('a, button, input, select, textarea, [tabindex]:not([tabindex="-1"])');
          if ($submenuBlock.classList.contains('is-active')) {
            focusableElements.forEach(function (el) {
              return el.removeAttribute('tabindex');
            });
          } else {
            focusableElements.forEach(function (el) {
              return el.setAttribute('tabindex', '-1');
            });
          }
        });
      });
    });
    var $contentBlocks = document.querySelector('.content-blocks');
    var $navigationContainer = document.querySelector('.navigation-container');
    if ($contentBlocks && $contentBlocks.firstElementChild && $navigationContainer) {
      var $firstBlock = $contentBlocks.firstElementChild;
      var concernedBlocksClasses = ['hero-banner', 'box-banner', 'fullscreen'];
      concernedBlocksClasses.forEach(function (concernedBlockClass) {
        if ($firstBlock.classList.contains(concernedBlockClass)) {
          $contentBlocks.classList.add('has-hero-banner');
        }
      });
    }
    var $mobileMenuLinksWithChildren = document.querySelectorAll('.mobile-menu .nav-link.has-children');
    if ($mobileMenuLinksWithChildren.length) {
      $mobileMenuLinksWithChildren.forEach(function ($mobileMenuLinkWithChildren) {
        var panelId = $mobileMenuLinkWithChildren.dataset.menu;
        var $mobileMenuPanel = document.querySelector('.mobile-menu .sub-menu[data-submenu="' + panelId + '"]');
        if ($mobileMenuPanel) {
          $mobileMenuLinkWithChildren.addEventListener('click', function (e) {
            e.preventDefault();
            $mobileMenuPanel.classList.toggle('is-active');
            var $backLink = $mobileMenuPanel.querySelector('.back-link');
            if ($backLink) {
              $backLink.addEventListener('click', function (e) {
                e.preventDefault();
                $mobileMenuPanel.classList.remove('is-active');
              });
            }
          });
        }
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();