"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ImageGalery = function ImageGalery() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allFilters = document.querySelectorAll(".image-filter");
    var allNavLinks = document.querySelectorAll(".nav-link");
    var imagePage = document.querySelector(".content-images-container");
    var videoPage = document.querySelector(".content-videos-container");
    var graphicsStandardPage = document.querySelector(".content-graphicsStandard-container");
    var logoPage = document.querySelector(".content-logos-container");
    var allPages = document.querySelectorAll(".content-container");
    var allImages = document.querySelectorAll(".image-content");
    if (allFilters) {
      allFilters.forEach(function (filter) {
        filter.addEventListener('click', function (event) {
          var cat = filter.dataset.cat;
          var allImagesActive = document.querySelectorAll(".".concat(cat));
          removeFilterIsActive();
          if (cat == "all") {
            allImages.forEach(function (image) {
              image.style.display = "flex";
            });
          } else {
            allImagesActive.forEach(function (image) {
              image.style.display = "flex";
            });
          }
          filter.classList.add("is-active");
        });
      });
      var removeFilterIsActive = function removeFilterIsActive() {
        allImages.forEach(function (image) {
          image.style.display = "none";
        });
        allFilters.forEach(function (filter) {
          filter.classList.remove("is-active");
        });
      };
    }
    if (allNavLinks) {
      allNavLinks.forEach(function (navLink) {
        navLink.addEventListener('click', function (event) {
          var page = navLink.dataset.page;
          allPages.forEach(function (page) {
            return page.style.display = "none";
          });
          switch (page) {
            case "nav-link-image":
              imagePage.style.display = "flex";
              if (document.body.offsetWidth < 1023) {
                window.scrollTo({
                  top: imagePage.offsetTop - 100,
                  behavior: 'smooth'
                });
              }
              break;
            case "nav-link-video":
              videoPage.style.display = "flex";
              console.log(document.body.offsetWidth);
              if (document.body.offsetWidth < 1023) {
                window.scrollTo({
                  top: videoPage.offsetTop - 100,
                  behavior: 'smooth'
                });
              }
              break;
            case "nav-link-graphicsStandard":
              graphicsStandardPage.style.display = "flex";
              if (document.body.offsetWidth < 1023) {
                window.scrollTo({
                  top: graphicsStandardPage.offsetTop - 100,
                  behavior: 'smooth'
                });
              }
              break;
            case "nav-link-logo":
              logoPage.style.display = "flex";
              if (document.body.offsetWidth < 1023) {
                window.scrollTo({
                  top: logoPage.offsetTop - 100,
                  behavior: 'smooth'
                });
              }
              break;
          }
          removeNavLinkIsActive();
          navLink.classList.add("is-active");
        });
      });
      var removeNavLinkIsActive = function removeNavLinkIsActive() {
        allNavLinks.forEach(function (navLink) {
          navLink.classList.remove("is-active");
        });
      };
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();