"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.AnchorsInDrawers = function AnchorsInDrawers() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var searchForElement = function searchForElement(anchor) {
      // Search anchor in drawers
      var anchorElement = document.getElementById(anchor);
      if (anchorElement) {
        // Check if the element is in a drawer
        var parentDrawer = anchorElement.closest('.drawers');
        if (parentDrawer) {
          setTimeout(function () {
            // Open concerned drawers and scroll to anchor
            var concernedDrawer = anchorElement.closest('.accordion-item');
            var concernedDrawerContent = concernedDrawer.querySelector('.accordion-content');
            var allDrawers = parentDrawer.querySelectorAll('.accordion-item');
            allDrawers.forEach(function (drawer) {
              drawer.classList.remove('is-opened');
              var drawerContent = drawer.querySelector('.accordion-content');
              drawerContent.style.maxHeight = null;
            });
            concernedDrawer.classList.add('is-opened');
            concernedDrawerContent.style.maxHeight = '100%';
            setTimeout(function () {
              // Smooth scroll to anchor
              anchorElement.scrollIntoView({
                behavior: 'smooth'
              });
            }, 1200);
          }, 200);
        }
      }
    };

    // Get anchor from URL
    var getAnchorFromUrl = function getAnchorFromUrl() {
      var urlAnchor = window.location.hash.substr(1);
      if (urlAnchor.length > 0) {
        searchForElement(urlAnchor);
      }
    };

    // Get anchor on click
    window.addEventListener('hashchange', function () {
      getAnchorFromUrl();
    });
    getAnchorFromUrl();
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();