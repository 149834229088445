"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.PartnersCompaniesList = function PartnersCompaniesList() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $phoneBookForms = document.querySelectorAll('.partners-list .form-section-wrapper');
    if ($phoneBookForms.length) {
      $phoneBookForms.forEach(function ($phoneBookForm) {
        var listType = $phoneBookForm.closest('.partners-list').getAttribute('data-list-type');
        var handlePagination = function handlePagination() {
          var $showMoreButton = $phoneBookForm.closest('.content-wrapper').querySelector('.show-more-button');
          if ($showMoreButton) {
            console.log($showMoreButton);
            $showMoreButton.addEventListener('click', function (e) {
              e.preventDefault();
              console.log('click');
              $page.value = $showMoreButton.dataset.page;

              // Remove button
              var $showMoreWrapper = $showMoreButton.closest('.show-more-wrapper');
              $showMoreWrapper.remove();
              submitForm();
            });
          }
        };

        // Play with filters
        var $region, $mode, $speciality;
        $region = $phoneBookForm.querySelector('[name="region"]');
        if (listType !== 'companies') {
          $mode = $phoneBookForm.querySelector('[name="mode"]');
        } else {
          $speciality = $phoneBookForm.querySelector('[name="speciality"]');
        }
        var $page = $phoneBookForm.querySelector('[name="page"]');
        var submitForm = function submitForm() {
          var region, mode, speciality;
          region = $region.value;
          if (listType !== 'companies') {
            mode = $mode ? $mode.value : null;
          } else {
            speciality = $speciality.value;
          }
          var page = $page.value;
          var csrf = $phoneBookForm.querySelector('[name="CRAFT_CSRF_TOKEN"]').value;
          var $resultsSections = $phoneBookForm.closest('.content-wrapper').querySelector('.results-section');
          var bodyFormData = new FormData();
          bodyFormData.append('region', region);
          if (listType !== 'companies') {
            bodyFormData.append('mode', mode);
          } else {
            bodyFormData.append('speciality', speciality);
          }
          bodyFormData.append('page', page);
          bodyFormData.append('CRAFT_CSRF_TOKEN', csrf);
          axios({
            method: "post",
            url: "/actions/sitemodule/default/".concat(listType === 'companies' ? 'search-companies' : 'search-partners'),
            data: bodyFormData
          }).then(function (response) {
            //handle success
            var data = response.data;
            var doc = new DOMParser().parseFromString(data, "text/xml");
            var $firstElement = doc.querySelector('.result-card');
            if ($firstElement) {
              $resultsSections.classList.remove('no-results');
              $resultsSections.classList.add('has-results');
              if ($firstElement.getAttribute('data-page') === '1') {
                $resultsSections.innerHTML = '';
              }
            } else {
              $resultsSections.classList.remove('has-results');
              $resultsSections.classList.add('no-results');
              $resultsSections.innerHTML = '';
            }
            $resultsSections.innerHTML += data;
            handlePagination();
          }).catch(function (response) {
            //handle error
            console.log(response);
          });
        };
        $region.addEventListener('change', function (e) {
          $page.value = 1;
          submitForm();
        });
        if (listType === 'companies') {
          $speciality.addEventListener('change', function (e) {
            $page.value = 1;
            submitForm();
          });
        }
        handlePagination();
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();