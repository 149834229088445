"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ConversionFactors = function ConversionFactors() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $mainUnits = document.querySelectorAll('.main-unit-selector p');
  var $dropdownTriggers = document.querySelectorAll('.unit-selector');
  var $mainUnitText = document.querySelector('.selected-main-unit');
  var $converterUnits = document.querySelectorAll('.rate-unit-selector p');
  var $converterUnitText = document.querySelector('.selected-rate-unit');
  var $submitConversion = document.querySelector('[action-submit-conversion]');
  var $conversionText = document.querySelector('[action-convert-result]');
  var $conversionQty = document.querySelector('input[name="conversion-qty"]');
  var $errorMessage = document.querySelector('.calculator-error');
  var converterArray = {
    'Bcfm3': 28316840.000000000000000,
    'BcanGjour': 2939.520000000000000,
    'Bcanmjour': 77580.400000000000000,
    'BtuPi3': 0.001000000000000,
    'GJm3': 26.392000000000000,
    'GJMpc': 0.947816987913438,
    'GjourBcan': 0.000340191595907,
    'm3GJ': 0.037890269778721,
    'm3Bcf': 0.000000035314675,
    'mjourBcan': 0.000012889853623,
    'MpcGJ': 1.055056000000000,
    'Pi3Btu': 1000.000000000000000,
    '$GigaCmetre3': 3.789000000000000,
    'Cmetre3$Giga': 0.263921879123779
  };

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    if ($mainUnits.length && $converterUnits.length && $dropdownTriggers.length && $submitConversion) {
      $dropdownTriggers.forEach(function ($dropdownTrigger) {
        $dropdownTrigger.addEventListener('click', function () {
          $dropdownTrigger.querySelector('.unit-dropdown').classList.toggle('is-active');
          $dropdownTrigger.querySelector('.dropdown-arrow').classList.toggle('is-active');
        });
      });
      var currentMain = '';
      $mainUnits.forEach(function ($mainUnit) {
        $mainUnit.addEventListener('click', function () {
          currentMain = $mainUnit.dataset.mainUnit;
          $mainUnitText.textContent = currentMain;
          $mainUnitText.classList.remove('is-placeholder');
          $mainUnitText.previousElementSibling.classList.add('is-active');
          var _iterator = _createForOfIteratorHelper($converterUnits),
            _step;
          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var $converterUnit = _step.value;
              var currentUnitArray = $converterUnit.dataset.availableUnit;
              if (currentUnitArray.includes(currentMain)) {
                $converterUnit.classList.remove('is-disabled');
              } else {
                $converterUnit.classList.add('is-disabled');
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
          if ($conversionQty.value > 0 && currentUnit && currentMain) {
            $submitConversion.disabled = false;
          }
        });
      });
      var currentUnit = '';
      var currentUnitTitle = '';
      $converterUnits.forEach(function ($converterUnit) {
        $converterUnit.addEventListener('click', function () {
          currentUnit = $converterUnit.dataset.rateUnit;
          $converterUnitText.textContent = currentUnit;
          currentUnitTitle = $converterUnit.dataset.unitName;
          $converterUnitText.classList.remove('is-placeholder');
          $converterUnitText.previousElementSibling.classList.add('is-active');
          if ($conversionQty.value > 0 && currentUnit && currentMain) {
            $submitConversion.disabled = false;
          }
        });
      });
      ['keyup', 'keydown', 'change', 'blur'].forEach(function (event) {
        $conversionQty.addEventListener(event, function () {
          if ($conversionQty.value > 0 && currentUnit && currentMain) {
            $submitConversion.disabled = false;
          }
          if ($conversionQty.value) {
            $conversionQty.previousElementSibling.classList.add('is-active');
          } else {
            $conversionQty.previousElementSibling.classList.remove('is-active');
          }
        });
      });
      $submitConversion.addEventListener('click', function () {
        var convertRate = converterArray[currentMain + currentUnit];
        if (convertRate) {
          var resultTotal = "".concat(convertRate * $conversionQty.value);
          $conversionText.textContent = new Intl.NumberFormat('en-CA', {
            maximumSignificantDigits: 3
          }).format(resultTotal) + " ".concat(currentUnitTitle);
          $errorMessage.classList.remove('is-visible');
          document.querySelector('.result-wrapper').classList.add('is-active');
        } else {
          $errorMessage.classList.add('is-visible');
        }
      });
    }
    inited = true;
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();