"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Footer = function Footer() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allAccordeon = document.querySelectorAll(".accordeon-footer");
    if (allAccordeon) {
      allAccordeon.forEach(function (element) {
        element.addEventListener('click', function (e) {
          e.stopPropagation();
          element.classList.toggle('is-opened');
        });
      });
    }
    setTimeout(function () {
      var cookiesSettingsBtn = document.querySelector("#ot-sdk-btn");
      if (cookiesSettingsBtn) {
        var text = cookiesSettingsBtn.dataset.buttonText;
        cookiesSettingsBtn.innerHTML = text;
      }
    }, 1000);
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();