"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.TabsContent = function TabsContent() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;
  var $tabHeaders = document.querySelectorAll('.tab-header');
  var $tabContents = document.querySelectorAll('.tab-content-container');

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    if ($tabHeaders) {
      var checkForSliders = function checkForSliders($element) {
        var $sliders = $element.querySelectorAll('.content-with-slider .column-side.slider');
        if ($sliders.length) {
          $sliders.forEach(function ($slider) {
            var flkty = Flickity.data($slider);
            flkty.resize();
          });
        }
      };
      $tabHeaders.forEach(function ($tabHeader) {
        $tabHeader.addEventListener('click', function () {
          var currentIndex = $tabHeader.dataset.tabIndex;
          var currentMobileIndex = $tabHeader.dataset.tabMobileIndex;
          $tabHeaders.forEach(function (i) {
            i.classList.remove('is-active');
            i.classList.remove('is-hidden');
          });
          $tabContents.forEach(function (i) {
            return i.classList.remove('is-active');
          });
          $tabHeader.classList.add('is-active');
          if ($tabHeader.previousElementSibling) {
            $tabHeader.previousElementSibling.classList.add('is-hidden');
          }
          var selectedTab = document.querySelector('[data-tab-content="' + currentIndex + '"]');
          if (selectedTab) {
            selectedTab.classList.add('is-active');
            checkForSliders(selectedTab);
          }
          ;
          var selectedMobileTab = document.querySelector('[data-tab-mobile-content="' + currentMobileIndex + '"]');
          if (selectedMobileTab) {
            selectedMobileTab.classList.add('is-active');
            checkForSliders(selectedMobileTab);
          }
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();