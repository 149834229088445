"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Graphs = function Graphs() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $graphs = document.querySelectorAll('.graph-container');
    if ($graphs.length) {
      var colors = ['#009fdf', '#0047bb', '#002855'];
      var getGraphWidth = function getGraphWidth() {
        var $graphContainer = document.querySelector('.graph-and-content .container-image');
        return $graphContainer.offsetWidth;
      };

      // Set chart options
      var options = {
        width: getGraphWidth(),
        height: 200,
        legend: 'none',
        vAxis: {
          minValue: 0
        },
        backgroundColor: {
          fill: '#FFFFFF',
          fillOpacity: 0
        },
        chartArea: {
          width: '80%',
          height: '80%'
        },
        tooltip: {
          trigger: 'none'
        }
      };
      $graphs.forEach(function ($graph) {
        // Load the Visualization API and the corechart package.
        google.charts.load('current', {
          'packages': ['corechart', 'bar']
        });

        // Set a callback to run when the Google Visualization API is loaded.
        google.charts.setOnLoadCallback(drawChart);
        function getData() {
          // Create the data table.
          var dataset = JSON.parse($graph.dataset.chartsJson);
          var datatable = [];
          datatable.push(['Title', 'Cost', {
            role: 'style'
          }, {
            role: 'annotation'
          }]);
          dataset.forEach(function (item, index) {
            datatable.push([item.title, item.cost, colors[index], item.annotation]);
          });
          var data = google.visualization.arrayToDataTable(datatable);
          return data;
        }

        // Callback that creates and populates a data table,
        // instantiates the pie chart, passes in the data and
        // draws it.
        function drawChart() {
          var data = getData();
          var chart = new google.visualization.ColumnChart($graph);
          options.width = getGraphWidth();
          chart.draw(data, options);
        }
        window.addEventListener("resize", drawChart);
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();