"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.ImageWithCaption = function ImageWithCaption() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var allSection = document.querySelectorAll(".block-image-with-caption");
    if (allSection) {
      allSection.forEach(function (e) {
        e.addEventListener('click', function (el) {
          var top = el.pageY - 400;
          removeVisible();
          e.classList.add('is-visible');
          window.scrollTo({
            top: top,
            behavior: 'smooth'
          });
        });
      });
    }
    var removeVisible = function removeVisible() {
      allSection.forEach(function (element) {
        element.classList.remove('is-visible');
      });
    };
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();