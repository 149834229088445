/*!
 * Flickity imagesLoaded v2.0.0
 * enables imagesLoaded option for Flickity
 */

/*jshint browser: true, strict: true, undef: true, unused: true */

( function( window, factory ) {
  // universal module definition
  /*jshint strict: false */ /*globals define, module, require */
  if ( typeof define == 'function' && define.amd ) {
    // AMD
    define( [
      'flickity/js/index',
      'imagesloaded/imagesloaded'
    ], function( Flickity, imagesLoaded ) {
      return factory( window, Flickity, imagesLoaded );
    });
  } else if ( typeof module == 'object' && module.exports ) {
    // CommonJS
    module.exports = factory(
      window,
      require('flickity'),
      require('imagesloaded')
    );
  } else {
    // browser global
    window.Flickity = factory(
      window,
      window.Flickity,
      window.imagesLoaded
    );
  }

}( window, function factory( window, Flickity, imagesLoaded ) {
  'use strict';

  Flickity.createMethods.push('_createImagesLoaded');

  var proto = Flickity.prototype;

  proto._createImagesLoaded = function() {
    this.on( 'activate', this.imagesLoaded );
  };

  proto.imagesLoaded = function() {
    if ( !this.options.imagesLoaded ) {
      return;
    }
    var _this = this;
    function onImagesLoadedProgress( instance, image ) {
      var cell = _this.getParentCell( image.img );
      _this.cellSizeChange( cell && cell.element );
      if ( !_this.options.freeScroll ) {
        _this.positionSliderAtSelected();
      }
    }
    imagesLoaded( this.slider ).on( 'progress', onImagesLoadedProgress );
  };

  return Flickity;

}));
