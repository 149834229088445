"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.BlogHeroSlider = function BlogHeroSlider() {
  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $blogSliders = document.querySelectorAll('.blog-slider');
    if ($blogSliders.length) {
      $blogSliders.forEach(function ($blogSlider) {
        var flkty = new Flickity($blogSlider, {
          // options
          cellAlign: 'left',
          contain: true,
          prevNextButtons: true,
          imagesLoaded: true
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
};