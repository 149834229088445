"use strict";

window.site = window.site || {};

/**
 * Utilities for Google Analytics.
 * @class Track
 * @static
 */
site.Buttons = function Buttons() {
  'use strict';

  /**
   * Has the class been initialized?
   * @private
   */
  var inited = false;

  /**
   * Initializes the class.
   * @public
   */
  var init = function init() {
    // Abort if already initialized
    if (inited) {
      return false;
    }
    inited = true;
    var $buttons = document.querySelectorAll('.button');
    if ($buttons.length) {
      $buttons.forEach(function ($button) {
        $button.addEventListener('mousedown', function (e) {
          e.preventDefault();
          $button.classList.add('is-clicked');
        });
        $button.addEventListener('mouseup', function (e) {
          e.preventDefault();
          $button.classList.remove('is-clicked');
        });
        $button.addEventListener('mouseleave', function (e) {
          e.preventDefault();
          $button.classList.remove('is-clicked');
        });
      });
    }
    return true;
  };

  // Expose public methods & properties
  return {
    init: init
  };
}();